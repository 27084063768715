/* eslint-disable no-unused-vars */

const base = require('base/product/base');
var imagesloaded = require('imagesloaded');

var zoomConfigs = require('../config/zoomConfigs');
var device = require('../utils/device');

/**
 * Disable PDP Zoom
 */

// eslint-disable-next-line require-jsdoc
function disableZoom() {
    $('.slide-link').trigger('zoom.destroy');
}

/**
 * Add ADA alt attributes to zoom images
 */

// eslint-disable-next-line require-jsdoc
function addAltAttributes($image) {
    const $originalImage = $image.find('img:not(.zoomImg)');
    const originalAltText = $originalImage.attr('alt');
    $image.find('img.zoomImg').each(function (index, element) {
        let newAltText = originalAltText + ' Zoomed Version ' + (index + 1);
        $(element).attr('alt', newAltText);
    });
}

/**
 * Init PDP Zoom
 */

// eslint-disable-next-line require-jsdoc
function initZoom() {
    disableZoom();
    var isDesktop = device.desktop();
    var $activeSlide = $('.product__carousel__wrapper .js-product__carousel .slick-active');
    var $image = $activeSlide.find('.product__carousel__slide');
    var url = $image.data('rel');

    if ($image.length > 0 && url && url !== 'null' && isDesktop) {
        // Start spinner while zoom image loads
        $activeSlide.spinner().start();

        var config = {
            url: url,
            callback: function () {
                // Stop spinner when zoom image loaded
                $activeSlide.spinner().stop();
                addAltAttributes($image);
            }
        };
        config = $.extend({}, zoomConfigs, config);

        $(document).ready(function () {
            setTimeout(function () {
                $image.zoom(config);
            }, 2000);
        });
    }
}

/**
 * Update the PDP browser url with the ajax call parameters to support back button / refresh / shared link
 *
 * @param {jQuery} calledParams - product variations attributes
 */
function updateBrowserUrl(calledParams) {
    var updatedURL = document.location.href.split('?')[0] + '?' + calledParams;
    history.pushState(null, null, updatedURL);
}

/**
 * Updates the button tangiblee to add it to the wrapper class
 * and update the button text
 */
function updateTangibleeButton() {
    const tangibleeInterval = setInterval(function () {
        if ($('.tangiblee-cta').length > 0 && $('.buttons-wrapper').length > 0) {
            clearInterval(tangibleeInterval);
            var $tangibleeCTA = $('.tangiblee-cta');
            $tangibleeCTA.children('.tangiblee-cta__img').remove();
            $tangibleeCTA.appendTo('.buttons-wrapper');
            $('.tangiblee-cta__title').text($('.buttons-wrapper').data('btn-msg'));
        }
    }, 100);
}

const enableCarousel = () => {
    const thumbCarouselClass = '.js-product__carousel--thumb';
    const $thumbCarousel = $(thumbCarouselClass);
    const carouselClass = '.js-product__carousel';
    const $carousel = $(carouselClass);
    const $imageContainer = $('.js-product__carousels');

    if ($thumbCarousel.length && $carousel.length) {
        imagesloaded($carousel.add($thumbCarousel)).on('always', function () {
            $carousel.on('init', () => {
                $imageContainer.css('height', 'auto');
                $imageContainer.addClass('initialized');
                initZoom();
            });

            $carousel.on('afterChange', (e, slick, currentSlide) => {
                initZoom();
                var $firstSliderImage = $carousel.find('.slick-slide[data-slick-index="' + currentSlide + '"] .product__carousel__image').first();
                // Necessary for slick slider ADA issues SPDO-1626
                $('.slick-slide').removeAttr('aria-hidden');
                $('.syte-discovery.shop-similar__link')
                    .attr('data-image-src', $firstSliderImage ? $firstSliderImage.attr('src') : null);
            });

            const carouselOptions = {
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: carouselClass,
                dots: false,
                arrows: false,
                focusOnSelect: true,
                variableWidth: true,
                infinite: false
            };
            $thumbCarousel.slick(carouselOptions);

            const carouselThumbOptions = {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                asNavFor: thumbCarouselClass,
                infinite: false,
                dots: true,
                mobileFirst: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        dots: false,
                        arrows: false
                    }
                }]
            };
            $carousel.slick(carouselThumbOptions);
        });
    } else {
        $imageContainer.addClass('initialized');
    }
    $thumbCarousel.on('afterChange init', function () {
        $(this).find("[aria-current='true']").removeAttr('aria-current');
        $(this).find('.slick-current').attr('aria-current', 'true');
    });
};

/**
 * Adjusts the size of the image container when variant is selected.
 */
function adjustImageContainerSize() {
    var imgContainerSizeW = $('.tile-image').width();
    var imgContainerSizeH = $('.tile-image').height();
    $('.image-container').css({ width: imgContainerSizeW, height: imgContainerSizeH });
    $('.image-container').addClass('plp-test-css-resize');
}
/**
 * Removes the size of the image container when screen is resized.
 */
function adjustImageContainerReset() {
    $('.image-container').width('100%').height('100%');
}

const renderCarousel = async (response) => {
    var isPDP = ($('.refinement-bar').length === 0);
    const animationDuration = 200;
    const $imageContainer = response.container ? $(response.container).find('.js-product__carousels') : $('.js-product__carousels');
    const product = response.data.product;
    const ProdPid = response.data.ProdPid;
    const varSale = response.data.varSale;
    const varBadgeTextTile = response.data.product.badgeTextTile;
    const swatchUrl = response.data.swatchUrl;
    const prodType = response.data.prodType;
    const queryString = response.data.queryString;
    const images = product.images || null;
    const videos = Object.entries(product.videos) || null;
    let videoAlt = 'Video';
    let carouselHtml = '';
    let carouselThumbHtml = '';
    $imageContainer.css('height', $imageContainer.height());
    const $tempImageContainer = response.container ? $(response.container).find('.js-product__carousels.primary-images') : $('.js-product__carousels.primary-images');
    var $productContainer = $imageContainer.closest('.product-detail');
    var imageContainerId = $productContainer.find('.image-container').attr('id');

    var selectedAttrs = [];
    $productContainer.find('.selected').each(function () {
        if ($(this).data('attr-id')) {
            selectedAttrs.push($(this).data('attr-id') + '-' + $(this).data('attr-value'));
        }
    });

    if (isPDP) {
        $imageContainer.removeClass('initialized');
    }

    if (isPDP && images && images.large) {
        images.large.forEach((image, index) => {
            carouselHtml += `
                <li class="product__carousel__slide sss" data-rel="${images.zoom && images.zoom[index] ? images.zoom[index].url : image.url}">
                    <img src="${image.url}" class="product__carousel__image" alt="${image.alt}" />
                </li>
            `;
        });
    }

    if (isPDP && images && images.small) {
        images.small.forEach(image => {
            carouselThumbHtml += `
                <li class="product__carousel__slide">
                    <img src="${image.url}" class="product__carousel__image" alt="${image.alt}" />
                </li>
            `;
            videoAlt = image.alt;
        });
    } else if (!isPDP && images && images.large) {
        var urlSuffix = '?';
        selectedAttrs.forEach((element) => {
            urlSuffix += `dwvar_${ProdPid}_${element.split('-')[0]}=${element.split('-')[1]}&`;
        });
        carouselThumbHtml += `
            <a class="image-container__link" href="${swatchUrl}${urlSuffix}">
                <img class="tile-image" src="${images.large[0].url}" alt="${images.large[0].alt}" />
            </a>
        `;
        videoAlt = images.large[0].alt;
    }


    if (isPDP && videos && videos.length > 0) {
        videos.forEach((video, index) => {
            carouselThumbHtml += `
                <li class="product__carousel__slide" aria-label="Video ${index} of ${videos.length}">
                    <img src="${response.data.thumbnailUrls[index]}?"  alt="${videoAlt}" />
                </li>
            `;
            carouselHtml += `
            <li class="product__carousel__slide" aria-label="Video ${index} of ${videos.length}">
            <iframe src="https://player.vimeo.com/video/${product.videos[index]}?sm=fit"
                width="699" height="699" frameborder="0"
                webkitallowfullscreen mozallowfullscreen allowfullscreen  alt="${videoAlt}" class="d-inline-block" /></iframe>
            </li>
        `;
        });
    }
/**
* use different classnames for PLP vs PDP
*/
    var CarouselWrapperClassName = isPDP ? 'product__carousel--thumb__wrapper d-none d-md-block' : '';
    var CarouselClassName = isPDP ? 'js-product__carousel--thumb product__carousel--thumb' : '';

    var html = '';
    if (isPDP) {
        html = `
            <div class="${CarouselWrapperClassName}">
                <ul
                    id="pdpThumbCarousel-${product.id}" class="${CarouselClassName}" role="listbox"
                >
                    ${carouselThumbHtml}
                </ul>
            </div>
            <div class="product__carousel__wrapper ${images.small.length + videos.length > 0 ? 'product__carousel__wrapper--with-thumb' : ''}">
                <ul
                    id="pdpCarousel-${product.id}"
                    class="js-product__carousel product__carousel"
                    role="listbox"
                >
                    ${carouselHtml}
                </ul>
            </div>
        `;
    } else {
        html = `
            <div class="${CarouselWrapperClassName}">
                <ul
                    id="pdpThumbCarousel-${product.id}" class="plp-image-no-margin" role="listbox"
                >
                    ${carouselThumbHtml}
                </ul>
            </div>
            <div class="body-regular-normal ${!varBadgeTextTile ? 'd-none' : ''} ${varSale ? 'badge-text-tile-sale' : 'badge-text-tile-evergreen'}">
                ${varBadgeTextTile}
            </div>
        `;
    }

    return new Promise((resolve) => {
        var lineItem = product;
        var productID = lineItem.masterID && lineItem.productType !== 'master' ? lineItem.masterID : lineItem.id;
        var imgContainerSuffix = ProdPid && prodType === 'variationGroup' ? ProdPid : productID;
        setTimeout(() => {
            const btnMsg = $('.buttons-wrapper').data('btn-msg');
            if (isPDP) {
                $imageContainer.html(html);
                $('.js-product__carousels.primary-images').append(`<div class="buttons-wrapper" data-btn-msg='${btnMsg}'></div>`);
                $('.buttons-wrapper').append($.parseHTML(product.syteDiscoverButton));
                updateTangibleeButton();
            } else {
                // for PLP only
                $tempImageContainer.addClass('d-none');
                adjustImageContainerSize();
                if (prodType === 'master') {
                    $('#image-container-' + productID).empty().html(html);
                } else if (prodType === 'variationGroup') {
                    $('#image-container-' + imgContainerSuffix).empty().html(html);
                } else {
                    $('#' + imageContainerId).empty().html(html);
                }
            }

            resolve();
        }, animationDuration);
    });
};

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        base.methods.editBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);

        $('.minicart-total').attr('data-gtmdata', response.cartMiniCartData);
    }
}

/**
 * handles swatch variation attribute changes
 * @param {Element} element - swatch element
 */
function updateSwatches(element) {
    var $valueElement = element.parents('.attribute').find('.display-attribute-value');
    if ($valueElement.length) {
        var displayValue = element.data('display-value');
        if (displayValue && element.find('.selected') && element.find('.selected').length) {
            $valueElement.empty().html(displayValue);
        } else {
            $valueElement.empty().html('');
        }
    }
}

base.initCarousels = function () {
    enableCarousel();

    const carouselClass = '.js-product__carousel';
    const $carousel = $(carouselClass);
    if ($carousel.length) {
        $carousel.on('init', initZoom);
        $carousel.on('afterChange', initZoom);
        $('.product__carousel__slide iframe').css('display', 'none');
    }

    $('body').on('product:afterAttributeSelect', async (e, response) => {
        if (response.data && response.data.product) {
            await renderCarousel(response);
            enableCarousel(response);
        }
    });

    $('body').on('editwishlistproduct:ready', () => {
        enableCarousel();
    });

    $(window).on('resize', function () {
        adjustImageContainerReset();
        const thumbCarouselClass = '.js-product__carousel--thumb';
        const $thumbCarousel = $(thumbCarouselClass);
        const countEl = $thumbCarousel.find('.slick-slide');
        // eslint-disable-next-line radix
        if (parseInt(countEl.length) < 5) {
            const slickTrack = $thumbCarousel.find('.slick-track');
            setTimeout(function () {
                slickTrack.attr('style', '');
            }, 500);
        }
        updateTangibleeButton();
    });
    updateTangibleeButton();

    let tangibleeInterval = $(window).on('load', function () {
        if ($('.tangiblee-cta').length > 0) {
            clearInterval(tangibleeInterval);
            updateTangibleeButton();
        }
    });

    if ($('.buttons-wrapper').length > 0) {
        const tangibleeBtnObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const addedNodes = Array.from(mutation.addedNodes);
                    addedNodes.forEach((node) => {
                        if ($(node).hasClass('tangiblee-cta')) {
                            $('.tangiblee-cta').appendTo('.buttons-wrapper');
                        }
                    });
                }
            }
        });

        const config = { childList: true, subtree: true };
        const targetNode = document.querySelector('.product-detail-wrapper');
        if (targetNode) {
            tangibleeBtnObserver.observe(targetNode, config);
        }
    }
};
/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
base.getQuantitySelector = function ($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
};

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
base.updateOptions = function (optionsHtml, $productContainer) {
	// Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
};

/**
 * update Wishlist Icon Ajax response
 *
 * @param {Object} response - response from Ajax call
 * @param {jQuery} $productContainer - DOM element for current product
 */
base.updateWishlistIcon = function (response, $productContainer) {
    if (response.itemExistinWishList) {
        $productContainer.find('.wishlistTile.js-add-to-wishlist').addClass('d-none');
        $productContainer.find('.wishlistTile.js-remove-from-wishlist').removeClass('d-none');
    } else {
        $productContainer.find('.wishlistTile.js-add-to-wishlist').removeClass('d-none');
        $productContainer.find('.wishlistTile.js-remove-from-wishlist').addClass('d-none');
    }
};

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
base.getAttributesHtml = function (attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
};


/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
base.updateAvailability = function (response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder && availabilityMessages.length < 1) {
        availabilityValue = '<li><span class="d-none">' + response.resources.info_selectforstock + '</span></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li>' + message + '</li>';
        });
    }

    $('body').trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
};

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
base.processSwatchValues = function (attr, $productContainer, msgs) {
    var isPDP = ($('.refinement-bar').length === 0);
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value.replace(/"/g, '') + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $swatchButton.attr('aria-checked', 'true');
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $swatchButton.attr('aria-checked', 'false');
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (isPDP) {
            if (attrValue.url) {
                $swatchButton.attr('data-url', attrValue.url);
            } else {
                $swatchButton.removeAttr('data-url');
            }
        }

        var plpMetal = !isPDP && attr.id === 'metal';
        // Refresh classes
        $attrValue.removeClass('unselectable');
        $attrValue.addClass(!plpMetal && (!attrValue.selectable || !attrValue.available) ? 'unselectable' : '');
    });
};

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
base.processNonSwatchValues = function (attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .custom-select-list.select-' + attr.id + ' li:first');
    $defaultOption.data('value', attr.resetUrl);
    var swatchOffset = 0;
    var isPLP = $('.refinement-bar').length > 0;

    if (attr.attributeId === 'centerStoneCaratWeight' || attr.attributeId === 'minCaratTotalWeight'
        || attr.attributeId === 'diamondEquivalent' || attr.id === 'hoopsize' || attr.id === 'mmsize' || attr.id === 'bandWidth') {
        swatchOffset = 3;
    } else if (attr.attributeId === 'zodiac' || attr.attributeId === 'initial') {
        swatchOffset = 4;
    }
    var selectableOptions = 0;
    var shownAttributes = 0;
    attr.values.forEach(function (attrValue) {
        if (attrValue.selectable || (attrValue.selected && attrValue.available)) {
            selectableOptions++;
        }
    });

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' .custom-select-list [data-attr-value="' + attrValue.value.replace(/"/g, '') + '"]');
        if ($attrValue && $attrValue.length > 0) {
            $attrValue.closest('li').data('value', attrValue.url);

            var keywordUnavailable = '- unavailable';
            var htmlText = $attrValue.html();
            var newkeyword = htmlText.replace(keywordUnavailable, '');
            $attrValue.html(newkeyword);

            if (!attrValue.selectable || (attrValue.selected && !attrValue.available)) {
                if ($attrValue.closest('ul').hasClass('custom-select-list-pdp-container')) {
                    $attrValue.html(newkeyword);
                } else {
                    $attrValue.html(newkeyword + keywordUnavailable);
                }
            }

            // Refresh classes
            var $classItem = $attrValue.closest('li');
            $classItem.removeClass('unselectable');
            $classItem.addClass(!attrValue.selectable || (attrValue.selected && !attrValue.available) ? 'unselectable' : '');
            $classItem.removeClass('selected');
            if ((isPLP && shownAttributes < swatchOffset) && !(!attrValue.selectable || (attrValue.selected && !attrValue.available))) {
                $classItem.removeClass('hide--selection');
                shownAttributes++;
            } else if (!isPLP && !(!attrValue.selectable || (attrValue.selected && !attrValue.available))) {
                $classItem.removeClass('hide--selection');
                shownAttributes++;
            } else {
                $classItem.addClass('hide--selection');
            }
            $classItem.attr('aria-selected', 'false');
            if (attrValue.selected) {
                $classItem.addClass('selected');
                $classItem.attr('aria-selected', 'true');
            }

            // Update selected attribute
            var $selectedAttrValue = $productContainer
                .find($attr + ' .btn-select [data-attr-value="' + attrValue.value.replace(/"/g, '') + '"]');
            if ($selectedAttrValue && $selectedAttrValue.length > 0) {
                $selectedAttrValue.html(newkeyword);
                if (!attrValue.selectable || (attrValue.selected && !attrValue.available)) {
                    $selectedAttrValue.html(newkeyword + keywordUnavailable);
                }
            }

            $attrValue.closest('li.selected').closest('div.attribute').find('.display-attribute-value').remove();
            $attrValue.closest('li.selected').closest('div.attribute').find('.selected-attr-value').text($attrValue.closest('a').attr('alt'));
            if (selectableOptions - shownAttributes > 0) {
                $attrValue.closest('li.selected').closest('div.attribute').find('.plp-variant-show').text('+' + (selectableOptions - swatchOffset));
            }
        }
    });
};

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} response - response
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
base.updateAttrs = function (response, attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = response && response.swatchableSwatches &&
    response.swatchableSwatches.length ? ['color', 'metal', 'zodiac'].concat(response.swatchableSwatches) : ['color', 'metal', 'zodiac'];
    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            base.processSwatchValues(attr, $productContainer, msgs);
        } else {
            base.processNonSwatchValues(attr, $productContainer);
        }
    });
};

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
base.createCarousel = function (imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
    adjustImageContainerReset();
};

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
base.handleVariantResponse = function (response, $productContainer) {
    var isPDP = ($('.refinement-bar').length === 0);
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        base.updateAttrs(response, response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    base.createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    if (isPDP) {
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    } else {
        $productContainer.find('.promotions-plp').empty().html(response.product.promotionsHtmlPLP);
    }

    base.updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(base.getAttributesHtml(response.product.attributes));
};

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
base.updateQuantities = function (quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        base.getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Updates the URL of the diamond room button based on the provided query string.
 *
 * @param {string} pid - The pid to append to the URL.
 * @return {void} This function does not return a value.
 */
function updateDiamondRoomUrl(pid) {
    var $btn = $('.diamond-room-button');
    var url = $btn.data('url');
    var variationUrl = appendToUrl(url, { pid: pid });

    $btn.attr('href', variationUrl);
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Element} $swatchButton - swatch element
 */
base.attributeSelect = function (selectedValueUrl, $productContainer, $swatchButton) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });
        if ($productContainer.prevObject.length) {
            $productContainer.prevObject.removeClass('is-invalid');
        }
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                base.handleVariantResponse(data, $productContainer);
                base.updateOptions(data.product.optionsHtml, $productContainer);
                base.updateQuantities(data.product.quantities, $productContainer);
                if ($swatchButton) {
                    updateSwatches($swatchButton);
                }
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
                if (data.isProductBrowserBackHistory === true) {
                    updateBrowserUrl(data.queryString);
                }
                base.updateWishlistIcon(data, $productContainer);
                updateDiamondRoomUrl(data.product && data.product.id);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
};

base.selectAttribute = function () {
    $(document).on('change', 'button[class*="select-"], select[class*="select-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        base.attributeSelect(e.currentTarget.value, $productContainer);
    });
};

base.colorAttribute = function () {
    $(document).on('click', '.attr-radiogroup button[role="radio"]', function (e) {
        e.preventDefault();
        var swatch = $(this).find('.swatch-value');
        if (swatch.length > 0 && !swatch.hasClass('selected')) {
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            base.attributeSelect($(this).attr('data-url'), $productContainer, $(this));
        }
    });
};

base.getPidValue = function ($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).data('pid');
    } else if ($('.cart-recommendations').length) {
        pid = $($el).data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
};

base.addToCart = function () {
    $(document).off('click', 'button.add-to-cart, button.add-to-cart-global');
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        if ($(this).hasClass('add-to-cart-wishlist')) return;

        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var $monogramItem = $('.product-monogram > div');
        if ($monogramItem.length > 0) {
            var errorMonoFlag = false;
            $monogramItem.each(function () {
                if ($(this).find('.form-group').hasClass('required')) {
                    var $input = $(this).find('input');
                    var pattern = $input.attr('pattern');
                    var regExp = new RegExp(pattern);
                    var value = $input.val();
                    var regExpValid = regExp.test(value);
                    window.console.log(regExpValid);
                    if (value === '' || !regExpValid) {
                        $input.addClass('is-invalid');
                        var errorMessage = $input.data('missing-error');
                        if (!regExpValid && value !== '') {
                            errorMessage = $input.data('regexp-error');
                        }
                        $input.next('.invalid-feedback').show().html(errorMessage);
                        errorMonoFlag = true;
                    } else {
                        $input.removeClass('is-invalid');
                        $input.next('.invalid-feedback').hide();
                    }
                }
            });
            if (errorMonoFlag === true) {
                return;
            }
        }

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val() || 1,
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = base.getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }
        var hkUUID = $productContainer.data('hk-uuid');

        // make a list of HK-set products
        if ($('[data-hk-uuid]').length && $('[data-hk-uuid]').data('hk-uuid')) {
            setPids = [];

            $('.add-to-cart-hk-pid').each(function (index, element) {
                setPids.push({
                    pid: $(element).val(),
                    hkuuid: hkUUID,
                    qty: 1,
                    isMaster: $(element).hasClass('hk-primary')
                });
            });

            pidsObj = JSON.stringify(setPids);
        }

        addToCartUrl = $('.add-to-cart-url').val();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: base.getQuantitySelected($(this))
        };

        var lashbrookUUID = $productContainer.data('lashbrook-uuid');
        if (lashbrookUUID) {
            form.lashbrookUUID = lashbrookUUID;
        }

        if (hkUUID) {
            form.hkUUID = hkUUID;
        }

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        if ($(this).hasClass('js-update-product')) {
            form.uuid = $(this).data('uuid');
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        $.spinner().stop();
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        base.miniCartReportingUrl(data.reportingURL);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};

module.exports = base;
