/* eslint-disable no-empty */
'use strict';

var cart = require('../cart/cart');
var cartUtils = require('../cart/utils');
var wishlistCart = require('wishlists/components/miniCart');
var keyboardAccessibility = require('base/components/keyboardAccessibility');
var focusHelper = require('base/components/focus');
var hkAvailability = require('../checkout/hkAvailability');
var hkUtils = require('../utils/hkutils');

var updateMiniCart = true;

/**
 * Send Focus to Close button after minicart open (ADA)
 */
function giveFocus() {
    $('body').on('keydown', '.minicart-link', function (e) {
        if (e.keyCode === 9) {
            $(this).blur();
            $('.minicart .close').focus();
        }
    });
}

/**
 * Trap Focus on Shopping Cart
 */
function trapFocus() {
    $('body').on('keydown', '#shoppingCartModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#shoppingCartModal',
            firstElementSelector: '.close',
            lastElementSelector: '.gpay-button',
            nextToLastElementSelector: '.checkout-cart'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
}

/**
 * Opens mini bag modal
 */
function openMiniBagModal() {
    $('.minicart-modal-anchor').addClass('show');
    setTimeout(function () {
        $('.minicart-link').addClass('d-none');
    }, 100);
}

/**
 * Close mini bag modal
 */
function closeMiniBagModal() {
    $('.minicart-modal-anchor').removeClass('show');
    setTimeout(function () {
        $('.minicart-link').removeClass('d-none');
    }, 150);
}

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            cartUtils.updateMinicartQuantity(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('tangiblee:addtocart', function (event, count) {
        $('.minicart').trigger('count:update', count);
        updateMiniCart = true;
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert alert-success add-to-basket-alert text-center" role="alert">'
            + 'Product added to bag'
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    });

    $('body').on('click', '.modal-title .close-button', function () {
        closeMiniBagModal();
    });

    $('.minicart').on('mouseenter keydown', function (e) {
        if (e.type === 'keydown' && e.keyCode !== 13) {
            return;
        }

        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        if (!updateMiniCart) {
            hkAvailability.minicart();
            openMiniBagModal();
            $('.toggle-minicart').attr('aria-expanded', 'true');
            hkUtils.scrollToHkUnavailable('minicart');
            $('body').trigger('minicart:shown');
            return;
        }
        openMiniBagModal();
        $('.toggle-minicart').attr('aria-expanded', 'true');
        $('.minicart-modal-anchor').spinner().start();
        $.get(url, function (data) {
            $('.minicart-modal-anchor').empty();
            $('.minicart-modal-anchor').append(data);
            updateMiniCart = false;

            var isPaypalEnabled = !!($('#paypal_enabled').length > 0 && document.getElementById('paypal_enabled').value === 'true');
            var isGooglePayEnabled = !!($('#isGooglePayEnabled').length > 0 && $('#isGooglePayEnabled').val() === 'true');

            if (isPaypalEnabled) {
                // eslint-disable-next-line
                paypalhelper.paypalMini();
            }
            if (isGooglePayEnabled) {
                // eslint-disable-next-line
                onGooglePayLoaded();
            }

            $('.minicart-link').blur();
            $('.minicart .close').focus();

            if ($('#shoppingCartModal .js-product-line-item[data-is-ship-to-store="true"]').length > 0) {
                $('.apple-pay-button-label-container').hide();
            }

            $.spinner().stop();
            hkUtils.scrollToHkUnavailable('minicart');

            $('body').trigger('minicart:shown');
        });
        event.stopImmediatePropagation();
    });

    $('.minicart-link').on('keydown', function (e) {
        if (e.type === 'keydown' && e.keyCode === 13) {
            e.preventDefault();
        }
    });

    giveFocus();
    trapFocus();

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
            $('.toggle-minicart').attr('aria-expanded', 'false');
        }
    });

    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        closeMiniBagModal();
        if (!($(document).find('.paypal-checkout-sandbox').length > 0)) {
            closeMiniBagModal();
        }
        $('.toggle-minicart').attr('aria-expanded', 'false');

        event.stopImmediatePropagation();
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line
            location.reload();
        }
    });
    $('body').on('click', '.minicart .popover .close', function () {
        $('.minicart .popover').removeClass('show');
        $('.toggle-minicart').attr('aria-expanded', 'false');
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
    $('body').on('keydown', '.minicart .close', function (e) {
        if (e.keyCode === 13) {
            $('.minicart-link').focus();
            $('.minicart .popover').removeClass('show');
            $('.toggle-minicart').attr('aria-expanded', 'false');
        }
    });

    wishlistCart.moveToWishlist();

    keyboardAccessibility('.toggle-minicart',
        {
            32: function ($minicart) { // spacebar
                $minicart.trigger('focusin');
                $minicart.trigger('mouseenter');
            }
        },
        function () {
            return $(this).closest('.minicart');
        }
    );
};
